import { Link } from 'gatsby';
import React, {
  FormEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CreateProtocolBasics from '../../components/CreateProtocolBasics';
import CreateProtocolResult from '../../components/CreateProtocolResult';
import Layout from '../../components/Layout';
import OverlayBox from '../../components/OverlayBox';
import Switch from '../../components/Switch';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { createPdf, getData, saveProtocol } from '../../utils/protocolHelpers';
import { protocolConfig } from '../../utils/protocolConfig';
import ProtocolMainImage from '../../components/ProtocolMainImage';
import ProtocolHeader from '../../components/ProtocolHeader';
import { useInterval, useQuery } from '../../utils/hooks';
import {
  CustomerBM,
  ProtocolBM,
  ProtocolOptionBM,
  ReservoirBM,
  UserFM,
} from '@bm-js/h2o-shared';
import { ActionType } from '../../types/dispatch.types';

const StyledCreateProtocolInspPage = styled.div`
  form {
    .dual {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      align-items: flex-start;
      p {
        margin-bottom: 0.5rem;
      }
    }
    h4 {
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
      font-size: 1.5rem !important;
      font-weight: 600 !important;
    }
  }
`;

const CreateProtocolInspPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [users, setUsers] = useState<UserFM[]>([]);
  const [reservoir, setReservoir] = useState<ReservoirBM | {}>({});
  const [customer, setCustomer] = useState<CustomerBM | {}>({});
  const [protocolOptions, setProtocolOptions] = useState<
    ProtocolOptionBM['keys'][0][]
  >([]);
  const [readyForAutoSave, setReadyForAutoSave] = useState(false);
  const [missingInformationWarning, setMissingInformationWarning] = useState({
    active: false,
    skipValidation: false,
  });

  const [protocolData, setProtocolData] = useState<ProtocolBM>({
    status: 'Ej påbörjat',
    // @ts-ignore
    nextRecommendedInspection: '',
    // @ts-ignore
    nextRecommendedCleaning: '',
    // @ts-ignore
    nextRecommendedFilterChange: '',
    // @ts-ignore
    nextRecommendedControl: '',
    // @ts-ignore
    dateForInspection: '',
    responsibleTechnician: '',
    orderPerson: '',
    contactPerson: '',
    isOcular: false,
    resultsInner: protocolConfig.insp.resultsInner,
    resultsOuter: protocolConfig.insp.resultsOuter,
  });
  const [previousProtocol, setPreviousProtocol] = useState<ProtocolBM>();

  const query = useQuery<{
    type: string;
    protocolId: string;
    reservoirId: string;
    customerId: string;
    customerName: string;
    reservoirName: string;
  }>();

  const autoSave = async () => {
    const _reservoir = reservoir as ReservoirBM;
    const _customer = customer as CustomerBM;
    await saveProtocol({
      dispatch,
      query,
      reservoir: _reservoir,
      state,
      protocolData,
      customer: _customer,
      isAutoSave: true,
    });
  };

  const { runningAction } = useInterval({
    action: autoSave,
    active: readyForAutoSave,
  });

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });
    if (state.token) {
      getData({
        dispatch,
        query,
        state,
        setCustomer,
        setReadyForAutoSave,
        setUsers,
        setReservoir,
        setProtocolOptions,
        setProtocolData,
        setPreviousProtocol,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  const _createPdf = (skipValidation?: boolean, skipDownload?: boolean) => {
    const _reservoir = reservoir as ReservoirBM;
    const _customer = customer as CustomerBM;
    createPdf({
      skipDownload,
      skipValidation,
      dispatch,
      query,
      setMissingInformationWarning,
      missingInformationWarning,
      protocolData,
      state,
      reservoir: _reservoir,
      customer: _customer,
    });
  };

  const _saveProtocol: FormEventHandler = (e) => {
    const _reservoir = reservoir as ReservoirBM;
    const _customer = customer as CustomerBM;
    e.preventDefault();
    saveProtocol({
      dispatch,
      state,
      query,
      reservoir: _reservoir,
      protocolData,
      customer: _customer,
    });
  };

  const mediaContext = useMemo(() => {
    return {
      customer: (customer as CustomerBM)._id,
      reservoir: (reservoir as ReservoirBM)._id,
      protocol: protocolData._id || '',
    };
  }, [customer, reservoir, protocolData]);

  return (
    <Layout>
      {readyForAutoSave && (
        <StyledCreateProtocolInspPage>
          <ProtocolHeader
            date={protocolData.dateForInspection as unknown as string}
            createPdf={_createPdf}
            query={query}
            runningAction={runningAction}
          />
          <div className="single-customer-inner">
            <div className="single-customer-left-content">
              <Card>
                <form onSubmit={_saveProtocol}>
                  <>
                    <CreateProtocolBasics
                      protocolData={protocolData}
                      setProtocolData={setProtocolData}
                      users={users}
                      customer={customer as CustomerBM}
                    />
                    <ProtocolMainImage
                      context={mediaContext}
                      imageKey={'mainImage'}
                      protocolData={protocolData}
                      setProtocolData={setProtocolData}
                    />
                    <h4>Inre inspektion</h4>
                    <Switch
                      allBlue
                      checked={protocolData.isOcular}
                      name="isOcular"
                      secondPlaceholder="Okulär"
                      placeholder="ROV"
                      onChange={(e) => {
                        setProtocolData({
                          ...protocolData,
                          isOcular: e.target.checked,
                        });
                      }}
                    />
                    <h4>Resultat - invändig inspektion</h4>
                    {protocolData.resultsInner.map((result, resultIndex) => (
                      <CreateProtocolResult
                        context={mediaContext}
                        key={result.key}
                        previousProtocol={
                          previousProtocol?.resultsInner?.[resultIndex]
                        }
                        mainKey={'resultsInner'}
                        result={result}
                        resultIndex={resultIndex}
                        protocolData={protocolData}
                        setProtocolData={setProtocolData}
                        protocolOptions={protocolOptions}
                      />
                    ))}
                    <h4>Resultat - utvändig inspektion</h4>
                    {protocolData.resultsOuter.map((result, resultIndex) => (
                      <CreateProtocolResult
                        context={mediaContext}
                        mainKey={'resultsOuter'}
                        key={result.key}
                        previousProtocol={
                          previousProtocol?.resultsOuter?.[resultIndex]
                        }
                        result={result}
                        resultIndex={resultIndex}
                        protocolData={protocolData}
                        setProtocolData={setProtocolData}
                        protocolOptions={protocolOptions}
                      />
                    ))}
                  </>

                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="submit"
                    style={{ display: 'none' }}
                    id="submitNewProtocolButton"
                  ></button>
                </form>
              </Card>
            </div>
            <div className="single-customer-right-content">
              <Card>
                <div className="customer-card-header">
                  <h3>Status</h3>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <select
                  value={protocolData.status}
                  onChange={(e) => {
                    setProtocolData({
                      ...protocolData,
                      status: e.target.value,
                    });
                  }}
                >
                  <option>Ej påbörjat</option>
                  <option>Hanteras</option>
                  <option>Klart</option>
                </select>
              </Card>
            </div>
          </div>
          <OverlayBox
            header="Information saknas"
            active={missingInformationWarning.active}
            close={() => {
              setMissingInformationWarning({
                ...missingInformationWarning,
                active: false,
              });
            }}
          >
            <p>
              Viss reservoarsinformation saknas för att skapa en komplett PDF.
              Följande information krävs på reservoarskortet:
            </p>
            <p>Namn</p>
            <p>Bottenyta</p>
            <p>Area</p>
            <br />
            <p>Hur vill du fortsätta</p>
            <Link
              to={`/reservoirs/single/?customerId=${(customer as CustomerBM)._id}&reservoirId=${(reservoir as ReservoirBM)._id}`}
              state={{
                openDialogue: 'edit',
              }}
            >
              <Button small>Komplettera information</Button>
            </Link>

            <Button
              small
              onClick={() => {
                _createPdf(true);
              }}
            >
              Fortsätt skapa PDF
            </Button>
            <Button
              small
              look="danger"
              onClick={() => {
                setMissingInformationWarning({
                  ...missingInformationWarning,
                  active: false,
                });
              }}
            >
              Avbryt
            </Button>
          </OverlayBox>
        </StyledCreateProtocolInspPage>
      )}
    </Layout>
  );
};

export default CreateProtocolInspPage;
